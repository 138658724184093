import React from "react"
import { css } from "@emotion/core"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import {
  faSoundcloud,
  faTwitter,
  faFacebook,
  faTumblr,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

import logo from "../../images/colophon-logo.svg"

/*
  @include media-breakpoint-up($sm-device) {
    width: auto;
    min-width: 10rem;
  }
  */
/*
    // Firefox Only
    @-moz-document url-prefix() {
      min-width: 314px;
      display: block;
      writing-mode: vertical-rl;
    }
    */
/*
      // Firefox Only
      @-moz-document url-prefix() {
        writing-mode: inherit;
      }
      */
const styles = css`
  display: inline-block;
  width: 100%;
  margin: 2rem 0;
  font-size: 1rem;
  padding: 1rem;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: var(--base02);
  font-family: serif !important;

  .colophon-header {
    margin-bottom: 2rem;

    > *:first-child:not(.colophon-title) {
      font-size: 0.8rem;
    }
    > .colophon-title {
      font-size: 2rem;
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: var(--base02);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: var(--base02);
      margin-bottom: 0.5rem;
      text-align: center;
      padding: 0.75rem 0;
      line-height: 2rem;
    }
    > *:last-child:not(.colophon-title) {
      > .colophon-left {
        float: left;
      }
      > .colophon-right {
        float: right;
      }
      ::after {
        clear: both;
      }
    }
  }
  .colophon-logo {
    text-align: center;
    padding: 1rem 0;
    img {
      width: 7rem;
    }
  }
  .colophon-body {
    padding: 1rem 0;
    line-height: 1.2rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 300px;

    > * {
      writing-mode: vertical-rl;
    }
    .colophon-body-right {
      font-size: 0.75rem;
    }
    dl {
      margin: 0 0.5rem;
      div {
        display: table-row;
        dt {
          display: table-cell;
          font-size: 1rem;
          padding-bottom: 1rem;
          padding-right: 1rem;
          text-align: justify;
          text-align-last: justify;
          vertical-align: middle;
          div {
            height: 100%;
            display: block;
          }
        }
        dd {
          display: table-cell;
          padding-right: 1rem;
          font-size: 1.2rem;
          text-align: justify;
          text-align-last: justify;
          vertical-align: middle;
        }
        &:first-child {
          dt {
            padding-right: 0rem;
          }
          dd {
            padding-right: 0rem;
          }
        }
      }
    }
    address {
      text-align: right;
      margin-bottom: 0;
      ul {
        padding-top: 0;
        list-style-type: none;
        font-size: 0.6rem;
        margin: 0 0.5rem;
        line-height: 1.1rem;
        display: inline-block;
        text-align: left;

        a[href] {
          text-decoration: none;
        }
      }
    }
    .colophon-body-left {
      font-size: 0.6rem;
      line-height: 1rem;
    }
  }
  .colophon-footer {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--base02);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--base02);
    font-size: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }
`

export default () => (
  <div css={styles}>
    <div class="colophon-header">
      <div>
        <div>Label: EPILOGUE RECORDS</div>
        <div>Atuhor: SHISHIDO HINATA</div>
      </div>
      <h3 class="colophon-title">シシドヒナタ</h3>
      <div>
        <div class="colophon-left">最終回レコード</div>
        <div class="colophon-right"></div>
      </div>
    </div>
    <div class="colophon-logo">
      <img src={logo} />
    </div>
    <div class="colophon-body">
      <div class="colophon-body-right">
        <time>平成二十九年 十一 月 四 日</time> 制作
      </div>
      <dl>
        <div>
          <dt>
            <div>演奏</div>
            <div>歌唱</div>
            <div>作詞</div>
            <div>作曲</div>
          </dt>
          <dd>シシドヒナタ</dd>
        </div>
        <div>
          <dt>ウェブ</dt>
          <dd>宍戸荘一郎</dd>
        </div>
        <div>
          <dt>レーベル</dt>
          <dd>最終回レコード</dd>
        </div>
      </dl>
      <address>
        <ul>
          <li>
            <a href="https://soundcloud/shishidohinata" title="Soundcloud">
              <Icon icon={faSoundcloud} />
              {` `}
              https://soundcloud/shishidohinata
            </a>
          </li>
          <li>
            <a href="https://twitter.com/shishidohinata" title="Twitter">
              <Icon icon={faTwitter} />
              {` `}
              https://twitter.com/shishidohinata
            </a>
          </li>
          <li>
            <a href="https://facebook.com/shishidohinata" title="Facebook">
              <Icon icon={faFacebook} />
              {` `}
              https://facebook.com/shishidohinata
            </a>
          </li>
          <li>
            <a href="https://shishidohinata.tumblr.com/" title="Tumblr">
              <Icon icon={faTumblr} />
              {` `}
              https://shishidohinata.tumblr.com/
            </a>
          </li>
          <li>
            <a href="mailto:shishidohinata@gmail.com" title="Email">
              <Icon icon={faEnvelope} />
              {` `}
              shishidohinata@gmail.com
            </a>
          </li>
        </ul>
      </address>
      <div class="colophon-body-left">
        乱丁・落丁本は、ご面倒ですが小社読者係宛ご送付
        <br />
        ください。送料小社負担にてお取り替えいたします。
      </div>
    </div>
    <div class="colophon-footer">© 最終回レコード Epilogue Records 2012</div>
  </div>
)
