import React from "react"
import { css } from "@emotion/core"

import Progress from "../../components/parts/progress"
import Layout from "../../components/layout/default"
import Player from "../../components/parts/player"
import Colophon from "../../components/parts/colophon"

export const metadata = {
  title: "Components",
}

export default () => (
  <Layout title={metadata.title}>
    <h1>{metadata.title}</h1>

    <h2>Progress</h2>

    <p
      css={css`
        .progress {
          width: 100%;
        }
      `}
    >
      <Progress max="200" value="30" />
      <Progress max="200" value="50" load="60" />
    </p>

    <h2>Player</h2>

    <Player src="/audios/shushi/hls/index.m3u8" />

    <h2>Colophon</h2>

    <Colophon></Colophon>
  </Layout>
)
